@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.mainFirst {
    background-image: url(/static/media/sarajevo.46ceb64a.jpg);
    background-size: cover;
    height: 500px;
    padding-top: 10%;
}

.shuttle {
    color: white;
    margin: 0 auto;
    text-align: center;
}

.mainSecond {
    text-align: center;
    padding: 5%;
    /* border-bottom: 1px solid #CCCDCF; */
    background-color: #dee2e6;
}

.mainThird {
    text-align: center;
}

h2 {
    margin: 0 auto;
    padding: 5%;
}

.box {
    /* border: 2px solid #5967c3; */
    border-radius: 5%;
    padding: 5%;
    margin: 1%;
    background-color: white;
}

.row {
    padding: 1%;
    margin: 0;
}

.img2 {
    height: 4em;
    width: 8em;
}

.img-3 {
    height: 8em;
    width: 12em;
}

.footer {
    background-color: white;
    color: #5967c3;
    text-align: center;
    border-top: 1px solid #5967c3 ;
}

.circle {
    border-radius: 50%;
    border: 1px solid #CCCDCF;
    height: 5em;
    width: 5em;
    margin: 0 auto;
}

.a {
    background-color: #5967c3;
}

.b {
    background-color: #5967c3;
}

.c {
    background-color: #5967c3;
}

i {
    color: white;
    margin-top: 0.7em;
}

.transfer {
    margin: 5% 0 5% 0;
}

.text {
    text-align: center;
    margin: 5%;
}

.info {
    border-top: 1px solid #CCCDCF;
    margin: 5%;
}

    .info h5 {
        margin-top: 5%;
    }

.text-airport, .text-rent, .text-transfer {
    text-align: center;
    padding: 5%;
    color: white;
    z-index: 1;
    position: relative;
    background-color: #5967c3;
}

.successField {
    background-color: #3a88db;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: -webkit-fill-available;
    flex-direction: column;
    flex-grow: 1;
}

.bg-dark{
  background-color: #5967c3!important;
}

.bg-dark a{
  color: white!important;
}

.bg-dark a:hover{
  font-weight: bold;
}

.text-airport .bg {
    position: absolute;
    opacity: 0.3;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

    .text-rent .bg {
        position: absolute;
        opacity: 0.3;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    .text-transfer .bg {
        position: absolute;
        opacity: 0.3;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

.contactsNames, .transfersName, .rentName, .airportName {
    display: flex;
    margin: 20px;
}

.contactsFirstName, .transfersFirstName, .rentFirstName, .airportFirstName {
    flex-grow: 1;
    margin-right: 5px;
}

.contactsLastName, .transfersLastName, .rentLastName, .airportLastName {
    flex-grow: 1;
    margin-left: 5px;
}

.contactsInput, .transfersInput, .rentInputs, .airpotInputs {
    margin-left: 20px;
    margin-right: 20px;
}

.contacts label, .transfersForm label, .rent label, .airport label {
    font-weight: bold;
    color: #5967c3;
}

.errorMsg {
    color: red;  
}

.contactsText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 15em;
    color: white;
    background-color: #5967c3;
}

.contactsHeader{
    margin-left: 20px;
    margin-right: 20px;
    font-weight: bold;
}

.contactsMain {
    margin-left: 20px;
    margin-right: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}

form {
    margin-left: 5%;
    margin-right: 5%;
    border: 1px solid #dadada;
    border-radius: 7px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #e9e9e9;
}

.contactsMain a {
    color: white;
}

.airport .errorMsg, .transfers .errorMsg, .rent .errorMsg{
    margin-left: 20px;
}

.mainSpecial {
    background-color: white;
}

.boxSpecial {
    /* border: 2px solid #5967c3; */
    /* border-radius: 5%; */
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin:5px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

    .boxSpecial p {
      margin-top: 3%;
      margin-bottom: 3%;
        font-weight: bold;
        text-align: center;
    }

.mainSpecialHeader {
    display: flex;
    justify-content: center;
}

.mainSpecialHeader h2{
    text-align: center;
}

.ui-widget-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(90, 90, 90, 0.1);
    z-index: 2;
}

.detailsPopup {
    position: fixed;
    width: 85%;
    height: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: white;
    z-index: 3;
    border: 1px solid #a1a1a1;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
}

.detailsHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10%;
    margin-top: 2%;
    margin-bottom: 2%;
    font-weight: bold;
    color: #3a88db;
}

.detailsMain {
    background-color: white;
    margin: 1em;
    border-radius: 7px;
    font-size: 1em;
    max-height: 75%;
    overflow: auto;
}

.detailsDetails {
    border: 2px solid #E9E9E9;
    border-radius: 7px;
    color: #3a88db;
    margin: 0.5em;
    padding: 0.5em;
}

.detailsPictures {
    border: 2px solid #E9E9E9;
    border-radius: 7px;
    color: #3a88db;
    margin: 0.5em;
    padding: 0.5em;
}

.detailsBtns {
  justify-content: center;
  align-items: flex-end;
  display: flex;
  margin: 2%;
  flex-grow: 1;
}
    .detailsBtns button{
        margin-left: 1em;
    }

    ul {
        list-style-type: none;
    }

div.row.rentInputs div.col-sm {
    padding-left: 0;
    padding-right: 0;
}

.boxSpecialBtns{
    margin: 0.5em;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.boxSpecialText p {
  text-align: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

div.row.rentInputs{
    padding: 0;
}

.react-notification-root .notification-container-top-center {
    top: 40%;
}

.detailsBtns a {
    color: white;
}

form {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    
}

.btnErrorMsg{
  display: flex;
}

.contactsMain img{
  height: 25px;
  width: 25px;
  margin-right: 5px;
}

.successHeader{
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: lightgreen;
  font-size: 24px;
}

.failureHeader{
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: lightcoral;
  font-size: 24px;
}

.successText{
  margin: 5%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.failureButton{
  margin: 10%;
  background: lightcoral;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid white;
  border-radius: 12px;
  height: 50px;
}

.successButton{
  margin: 10%;
  background: lightgreen;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid white;
  border-radius: 12px;
  height: 50px;
}

.successButton:hover{
  cursor: pointer;
}

.promotianalText{
  color: orange;
  font-weight: bold;
}

.labelStyle{
  font-weight: 500;
  font-size: 14px;
}

.inputStyle{
  color: #5967c3;
  background-color: white;
  border: 0;
  border-bottom: 1px solid gray;
  font-size: 0.9rem;
}

.purpleColorText{
  color: #5967c3;
}

.boxSpecial:hover{
  border:1px solid white;
  cursor: pointer;
}

.box:hover{
  border:1px solid white;
  cursor: pointer;
}

.promotionalTextHolder{
  display: flex;
}

.labelTextHolder{
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 35px;
    align-items: center;
    margin-top: -35px;
}

.labelTextHolder p, .labelTextHolder2 p{
  background-color: white;
  padding: 1.5%;
  border-radius: 20px;
  font-size: 1em;
}

.labelTextHolder2 {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 35px;
  align-items: center;
  margin-top: -35px;
}

.searchResultsSingleItem{
  height: 150px;
  width: 95%;
  margin-bottom: 20px;
  display: flex;
  border: 1px solid lightgray
}

.searchResultsSingleItem:hover{
  border: 2px solid #5967c3;
  cursor: pointer;
}

.directionResults{
  background-color: white;
  flex: 1 1;
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 2%;
}

.priceTextResults{
  background-color: white;
  flex: 0.7 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.resultsBtnHolder{
  /* background-color: orange; */
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectBtn{
  width: 80%;
  height: 40%;
  border-radius: 10px;
  background-color:#5967c3 ;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectBtn:hover{
  background-color: white;
  border: 3px solid #5967c3 ;
}

.selectBtn:hover p {
  color: #5967c3;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
}

.selectBtn p{
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
}

.pointsHolder{
  height: 100%;
  /* width: 100%; */
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.points{
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: #5967c3;
}

.line{
  width:  2px;
  height: 100%;
 flex: 1 1;
  background-color: #5967c3;
}

.doorToDoor{
  height: 60px;
  border-radius: 10px;
  background-color: #5967c3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
}

.purpleButton{
  background-color: #5967c3;
}

.soldOutText{
  background: red;
    height: 30px;
    color: white;
    border-radius: 10px;
    padding: 2px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
